import React from "react"
import { StaticImage } from "gatsby-plugin-image"
/*
const clients = [
  '../images/logo_1.png',
  '../images/logo_2.png',
  '../images/logo_3.png',
  '../images/logo_4.png',
  '../images/logo_5.png',
  '../images/logo_6.png',
  '../images/logo_7.png',
  '../images/logo_8.png',
  '../images/logo_9.png',
  '../images/logo_10.png',
  '../images/logo_11.png',
  '../images/logo_12.png',
  '../images/logo_13.png',
  '../images/logo_14.png',
  '../images/logo_15.png'
]
*/
export default function Clients() {
  return (
    <div className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
      <h2 className={'text-3xl lg:text-4xl mb-4 uppercase text-center'}>Some of the brands we work with</h2>
      <div className={'flex flex-wrap justify-center gap-x-8'}>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_1.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_2.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_3.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_4.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_5.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_6.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_7.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_8.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_9.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_10.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_11.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_12.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_13.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_14.png"
            alt=""
            layout="fullWidth"
          />
        </div>
        <div className={'w-32 md:w-40'}>
          <StaticImage
            src="../images/logo_15.png"
            alt=""
            layout="fullWidth"
          />
        </div>
      </div>
    </div>
  );
}
