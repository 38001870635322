import * as React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import Clients from "../components/Clients"
import { StaticImage } from "gatsby-plugin-image"

const AboutUsPage = () => {
  return (
    <Layout>
      <PageHeader title="About Us" breadcrumbs="Home /  About" />
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <div className={'lg:grid lg:grid-cols-2 lg:gap-16'}>
          <div>
            <div className={'lg:sticky lg:top-16 space-y-8'}>
              <div className={'prose-lg'}>
                <h2>The talent behind the reputation</h2>
                <p className={'lead'}>Design your interior to be timeless, sustainable, efficient and beautiful.</p>
                <p>Set within a converted historical building, Jo Andrews studio showcases an extensive library of luxurious fabrics from the top leading design houses from around the world.</p>
                <p>Our design library guarantees a unique and comprehensive styling to suit the traditional home through to the modern contemporary home. Our creative team offers the full compliment of design services including the highest quality window treatments and textile accessories to custom made bespoke furnishings and upholstery service.</p>
                <p>To add that finishing touch to your home we have cutting edge suppliers of occasional furniture, lighting and home accessories. The expertise of our designs and the skill of our craftspeople are evident in our showroom which is open 6 days a week.</p>
                <blockquote>“Every project we undertake should ultimately encapsulate our customers personality, desires and innate sense of style. Fabric and furnishings are selected with a view to reflect our clients home literally by complimenting the physical property but more importantly an interior should be an extension of themselves. The sheer opulence of fabrics and furnishings today are beyond exciting and make this process a labour of love.”</blockquote>
                <p><strong>Jo Andrews</strong></p>
              </div>
            </div>
          </div>
          <div>
            <div className={'lg:sticky lg:top-16 space-y-8'}>
              <StaticImage
                src="../images/about-us-1.jpeg"
                alt="About Us"
                layout="fullWidth"
              />
              <StaticImage
                src="../images/about-us-2.jpeg"
                alt="About Us"
                layout="fullWidth"
              />
            </div>
          </div>
        </div>
      </main>

        <Clients />
    </Layout>
  )
}

export default AboutUsPage

export const Head = () => (
    <>
    <title>About - Jo Andrews Interiors</title>
    <meta name="description" content="Jo Andrews Interiors encompasses interior design, interior accessories and bespoke furniture, made in Belfast, Northern Ireland." />
    </>
)
